<template>
    <WitModal v-model="showModal" :title="title" :variant="variant" @change="onChange">
        <slot></slot>
        <slot name="yesNoButtons">
            <b-row class="d-block text-center">
                <b-button :variant="yesVariant" size="md" @click="yesAction(yesProps)">Yes</b-button>
                <span class="pad-buttons" />
                <b-button :variant="noVariant" size="md" @click="noAction(noProps)">No</b-button>
            </b-row>
        </slot>
        <slot name="footer"></slot>
    </WitModal>
</template>

<script>
import WitModal from '@/components/Modals/WitModal.vue'

export default {
    components: {WitModal},
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: 'primary',
        },
        yesAction: {
            type: Function,
            required: true,
        },
        noAction: {
            type: Function,
            required: true,
        },
        yesProps: {
            type: String,
        },
        noProps: {
            type: String,
        },
        yesVariant: {
            type: String,
            default: 'danger',
        },
        noVariant: {
            type: String,
            default: 'success',
        },
    },
    watch: {
        value() {
            this.showModal = this.value
        },
        showModal() {
            this.onChange()
        },
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        onChange() {
            this.$emit('input', this.showModal)
        },
    },
}
</script>

<style>
.pad-buttons {
    padding-left: 2rem;
}
</style>
