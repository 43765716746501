<template>
    <YesNoModal
        v-model="showModal"
        title="Are you sure you want to remove this record?"
        variant="danger"
        :yesAction="removeElement"
        :yesProps="recordId"
        :noAction="closeModal"
        @change="onChange"
    >
        <h5>If you're sure you want to remove this record, click <strong>Yes</strong>.</h5>
        <slot name="error" />
    </YesNoModal>
</template>

<script>
import YesNoModal from '@/components/Modals/YesNoModal.vue'

export default {
    components: {YesNoModal},
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        removeElement: {
            type: Function,
            required: true,
        },
        recordId: {
            type: String,
        },
    },
    watch: {
        value() {
            this.showModal = this.value
        },
        showModal() {
            this.onChange()
        },
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        closeModal() {
            this.showModal = false
            this.$emit('input', this.showModal)
            this.$emit('close')
        },
        onChange() {
            this.$emit('input', this.showModal)
        },
    },
}
</script>

<style>
.pad-buttons {
    padding-left: 2rem;
}
</style>
